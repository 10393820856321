











































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, computed } from "@vue/composition-api";
import {
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  MolFormControl,
  MolSelectPeriod,
} from "@/v2/new-design-system";
import { useHelpers } from "@/v2/composable/use-helpers";
import dayjs from "dayjs";
import { t } from "@/i18n";

const css = bemBuilder("report-create");

interface IRangePeriod {
  start: Date;
  end: Date;
}

export default defineComponent({
  name: "ReportCreate",
  components: {
    AtomButton,
    AtomText,
    MolFormControl,
    MolSelectPeriod,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    userEmail: {
      type: String,
      required: true,
    },
    onCreate: {
      type: Function as PropType<(dates: IRangePeriod) => void>,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const rangePeriod = ref<IRangePeriod>({
      start: dayjs().startOf("day").toDate(),
      end: dayjs().endOf("day").toDate(),
    });

    const isLoading = ref(false);
    const hasError = ref(false);

    const currentBusiness = computed(() => {
      return useHelpers().getCurrentBusiness(props.businessId);
    });

    const timezone = computed(() => {
      return currentBusiness.value?.timezone;
    });

    const isDisabled = computed(() => {
      return !rangePeriod.value.start && !rangePeriod.value.end;
    });

    function setDate(dateRange: IRangePeriod) {
      rangePeriod.value = dateRange;
    }

    async function createReport() {
      try {
        isLoading.value = true;
        await props.onCreate(rangePeriod.value);
      } catch {
        hasError.value = true;
      } finally {
        isLoading.value = false;
      }
    }

    return {
      t,
      css,
      isDisabled,
      isLoading,
      setDate,
      hasError,
      timezone,
      createReport,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
