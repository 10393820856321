


























































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "@vue/composition-api";
import { OrgHeaderInfo } from "@/v2/new-design-system";
import { ReportCardList } from "../card-list";
import { ReportTypeEnum } from "@/v2/module/report/type.enum";
import { t } from "@/i18n";
import { unWatchFlag, watchFlag } from "@/v2/core/feature-flag";

const css = bemBuilder("report-main");

export default defineComponent({
  name: "ReportMain",
  components: {
    OrgHeaderInfo,
    ReportCardList,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup() {
    // Feature flag for the items sales
    const itemSalesFlagName = "report-items-sales";
    const canUseItemSalesReport = ref(false);
    const updateFlagItemSales = (val: boolean) =>
      (canUseItemSalesReport.value = val);

    // Feature flag for the customer ratings
    const customerRatingsFlagName = "report-customer-ratings";
    const canUseCustomerRatingsReport = ref(false);
    const updateFlagRatings = (val: boolean) =>
      (canUseCustomerRatingsReport.value = val);

    onMounted(() => {
      watchFlag(itemSalesFlagName, updateFlagItemSales);
      watchFlag(customerRatingsFlagName, updateFlagRatings);
    });

    onBeforeUnmount(() => {
      unWatchFlag(itemSalesFlagName, updateFlagItemSales);
      unWatchFlag(customerRatingsFlagName, updateFlagRatings);
    });

    return {
      t,
      css,
      ReportTypeEnum,
      canUseItemSalesReport,
      canUseCustomerRatingsReport,
    };
  },
});
