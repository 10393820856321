import {
  IFetchMetabaseDashboardLinkRequest,
  IFetchMetabaseDashboardLinkContract,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export class FetchMetabaseDashboardLinkError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchMetabaseDashboardLink");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchMetabaseDashboardLink(
  request: IFetchMetabaseDashboardLinkRequest
): Promise<string> {
  let response: AxiosResponse<IFetchMetabaseDashboardLinkContract>;

  try {
    response = await http.get(
      `metabase/outlets/${request.outletId}/signed-dashboard-link`,
      {
        params: { dashboard_id: request.dashboardId },
      }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchMetabaseDashboardLinkError(e);
  }

  return response.data.link;
}
