







































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, computed } from "@vue/composition-api";
import {
  AtomIcon,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomDate,
  AtomDateTypeEnum,
} from "@/v2/new-design-system";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { downloadReport } from "@/v2/repo/download-report";
import { ReportStatusEnum } from "@/v2/module/report/status.enum";
import { ReportTypeEnum } from "@/v2/module/report/type.enum";
import dayjs from "dayjs";
import { t } from "@/i18n";

const css = bemBuilder("report-item");

export default defineComponent({
  name: "ReportItem",
  components: {
    AtomButton,
    AtomIcon,
    AtomDate,
    AtomText,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    reportId: {
      type: String,
      required: true,
    },
    reportType: {
      type: String as PropType<ReportTypeEnum>,
      required: true,
    },
    tenantName: {
      type: String,
      required: true,
    },
    startedAt: {
      type: [Date, String],
      default: "",
    },
    filterDateStart: {
      type: Date,
      required: true,
    },
    filterDateEnd: {
      type: Date,
      required: true,
    },
    status: {
      type: String as PropType<ReportStatusEnum>,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);

    async function accessReport() {
      try {
        isLoading.value = true;
        const { file } = await downloadReport({
          businessId: props.businessId,
          reportId: props.reportId,
          reportType: props.reportType,
        });

        saveData(file);
      } catch {
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    function saveData(file: string) {
      const a = document.createElement("a");
      document.body.appendChild(a);

      const type = props.reportType.toLowerCase();
      const start = dayjs(props.filterDateStart).format("DD-MM-YYYY");
      const end = dayjs(props.filterDateEnd).format("DD-MM-YYYY");

      const blob = new Blob([file], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${type}_${start}_${end}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    }

    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      AtomDateTypeEnum,
      ReportStatusEnum,
      accessReport,
      isLoading,
    };
  },
});
