import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http } from "../http";
import { ReportTypeEnum } from "@/v2/module/report/type.enum";

interface ICreateReportBody {
  businessId: string;
  type: ReportTypeEnum;
  start: Date;
  end: Date;
}

export class CreateReportError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: CreateReport");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function createReport(
  request: ICreateReportBody
): Promise<Boolean> {
  let response: AxiosResponse;

  const mapTypes = {
    [ReportTypeEnum.CUSTOMER_DETAILS]: "customer-details",
    [ReportTypeEnum.ITEMS_SALES]: "items-sales",
    [ReportTypeEnum.LOYALTY_TRANSACTIONS]: "loyalty-transactions",
    [ReportTypeEnum.ORDER_HISTORY]: "order-history",
    [ReportTypeEnum.CUSTOMER_RATINGS]: "customer-ratings",
    [ReportTypeEnum.PAYMENT_LINKS]: "payment-links",
  };

  try {
    response = await http.post(
      `/api/v3/businesses/${request.businessId}/reports/${
        mapTypes[request.type]
      }`,
      { filter_date_start: request.start, filter_date_end: request.end }
    );
  } catch (err: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(err.isAxiosError);

    if (!isAxiosError) {
      throw err;
    }

    throw new CreateReportError(error);
  }

  return Boolean(response);
}
