





























import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  PropType,
} from "@vue/composition-api";
import { t } from "@/i18n";
import {
  fetchMetabaseDashboardLink,
  IFetchMetabaseDashboardLinkRequest,
} from "@/v2/repo/reports/fetch-metabase-dashboard-link";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomLoading,
} from "@/v2/new-design-system";
import { OutletSwitcher } from "@/v2/components/outlet-switcher";
import { report } from "@chatfood/bug-reporter";
import { ReportMetabaseDashboardEnum } from "@/v2/module/report/domain/metabase-dashboard.enum";

const css = bemBuilder("report-metabase-outlet-dashboard");

export default defineComponent({
  name: "MetabaseOutletDashboard",
  components: {
    AtomText,
    AtomLoading,
    OutletSwitcher,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    dashboardId: {
      type: Number as PropType<ReportMetabaseDashboardEnum>,
      required: true,
    },
    params: {
      type: Object as PropType<Record<string, string>>,
      default: undefined,
    },
  },
  setup(props) {
    const metabaseEl = ref();
    const link = ref();
    const isLoading = ref(true);
    const hasError = ref(false);
    const showIframe = ref(false);

    const reportLink = computed(() => {
      if (!link.value) return;

      const params = props.params
        ? `?${new URLSearchParams(props.params).toString()}`
        : "";

      return `${link.value}${params}#bordered=false&titled=false`;
    });

    const fetchLink = async (
      outletId: string,
      dashboardId: IFetchMetabaseDashboardLinkRequest["dashboardId"]
    ) => {
      isLoading.value = true;
      hasError.value = false;
      showIframe.value = false;

      try {
        link.value = await fetchMetabaseDashboardLink({
          outletId,
          dashboardId,
        });
        showIframe.value = true;
      } catch (error) {
        report(error);
        hasError.value = true;
        isLoading.value = false;
      }
    };

    watch(
      [() => props.outletId, () => props.dashboardId],
      () => {
        fetchLink(props.outletId, props.dashboardId);
      },
      { immediate: true }
    );

    const handleMetabasePostMessages = (message: MessageEvent) => {
      if (message.origin.includes("metabaseapp")) {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      window.addEventListener("message", handleMetabasePostMessages);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("message", handleMetabasePostMessages);
    });

    return {
      t,
      css,
      reportLink,
      metabaseEl,
      showIframe,
      isLoading,
      hasError,
      AtomTextTypeEnum,
    };
  },
});
