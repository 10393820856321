import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http, IHttpErrorResponse } from "../http";
import { RepoErrorReportEnum } from "../repo-error.enum";
import { ReportTypeEnum } from "@/v2/module/report/type.enum";
import { ReportStatusEnum } from "@/v2/module/report/status.enum";

interface IFetchReportsBody {
  businessId: string;
  type: ReportTypeEnum;
}

export interface IFetchReportItem {
  reportId: string;
  tenantName: string;
  startedAt: Date;
  filterDateStart: Date;
  filterDateEnd: Date;
  status: ReportStatusEnum;
}

export class FetchReportsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchReports");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchReports(
  request: IFetchReportsBody
): Promise<Array<IFetchReportItem>> {
  let response: AxiosResponse;

  const mapTypes = {
    [ReportTypeEnum.CUSTOMER_DETAILS]: "customer-details",
    [ReportTypeEnum.ITEMS_SALES]: "items-sales",
    [ReportTypeEnum.LOYALTY_TRANSACTIONS]: "loyalty-transactions",
    [ReportTypeEnum.ORDER_HISTORY]: "order-history",
    [ReportTypeEnum.CUSTOMER_RATINGS]: "customer-ratings",
    [ReportTypeEnum.PAYMENT_LINKS]: "payment-links",
  };

  try {
    response = await http.get(
      `/api/v3/businesses/${request.businessId}/reports/${
        mapTypes[request.type]
      }`
    );
  } catch (err: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(err.isAxiosError);

    if (!isAxiosError) {
      throw err;
    }

    const response: IHttpErrorResponse = err;

    switch (response.status) {
      case 404:
        error.errorCode = RepoErrorReportEnum.NOT_FOUND;
        break;
      default:
        error.networkError = true;
    }

    throw new FetchReportsError(error);
  }

  return response.data.data.map((obj: any) => ({
    reportId: obj.id,
    tenantName: obj.tenantName,
    startedAt: obj.startedAt ? new Date(obj.startedAt) : "",
    filterDateStart: new Date(obj.filterDateStart),
    filterDateEnd: new Date(obj.filterDateEnd),
    status: obj.status.toUpperCase(),
  }));
}
