


























































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  computed,
  watch,
} from "@vue/composition-api";
import {
  AtomCard,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomLoading,
  AtomButton,
  AtomModal,
  AtomAttentionBadge,
  AtomAttentionBadgeTypeEnum,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { ReportItem } from "../item";
import { ReportCreate } from "../create";
import { ReportTypeEnum } from "@/v2/module/report/type.enum";
import { IFetchReportItem, fetchReports } from "@/v2/repo/fetch-reports";
import { createReport } from "@/v2/repo/create-report";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import globalStore from "@/store";
import { t } from "@/i18n";

const css = bemBuilder("report-card-list");

interface IRangeDates {
  start: Date;
  end: Date;
}

export default defineComponent({
  name: "ReportCardList",
  components: {
    AtomCard,
    AtomText,
    AtomButton,
    AtomModal,
    AtomLoading,
    AtomAttentionBadge,
    ReportItem,
    ReportCreate,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<ReportTypeEnum>,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    headingDesc: {
      type: String,
      default: "",
    },
    isComingSoon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const showCreateModal = ref(false);
    const reportItems = ref<Array<IFetchReportItem>>([]);

    const { getters } = globalStore;
    const user = getters["auth/getData"];

    const userEmail = computed(() => {
      return user.email ?? "";
    });

    const titleModal = computed(() => {
      const mapTypes = {
        [ReportTypeEnum.CUSTOMER_DETAILS]: t(
          "module.report.create.heading_customer_details"
        ),
        [ReportTypeEnum.ITEMS_SALES]: t(
          "module.report.create.heading_items_sales"
        ),
        [ReportTypeEnum.LOYALTY_TRANSACTIONS]: t(
          "module.report.create.heading_loyalty_transactions"
        ),
        [ReportTypeEnum.ORDER_HISTORY]: t(
          "module.report.create.heading_order_history"
        ),
        [ReportTypeEnum.CUSTOMER_RATINGS]: t(
          "module.report.create.heading_customer_ratings"
        ),
        [ReportTypeEnum.PAYMENT_LINKS]: t(
          "module.report.create.heading_payment_links"
        ),
      };

      return mapTypes[props.type];
    });

    watch(
      () => props.businessId,
      () => {
        if (!props.isComingSoon) {
          mountReports();
        }
      }
    );

    watch(
      () => props.isComingSoon,
      (val) => {
        if (!val) {
          mountReports();
        }
      },
      {
        immediate: true,
      }
    );

    async function mountReports() {
      try {
        isLoading.value = true;

        const res = await fetchReports({
          businessId: props.businessId,
          type: props.type,
        });

        reportItems.value = res;
      } catch {
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    async function generateReport(rangeDates: IRangeDates) {
      try {
        await createReport({
          businessId: props.businessId,
          type: props.type,
          start: rangeDates.start,
          end: rangeDates.end,
        });

        showCreateModal.value = false;

        new Toast().create({
          type: "success",
          title: t("module.report.notify_success_title"),
          text: t("module.report.notify_success_desc"),
        });

        mountReports();
      } catch {
        throw Error;
      }
    }

    return {
      t,
      css,
      isLoading,
      userEmail,
      titleModal,
      reportItems,
      showCreateModal,
      generateReport,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomAttentionBadgeTypeEnum,
    };
  },
});
