import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http, IHttpErrorResponse } from "../http";
import { RepoErrorReportEnum } from "../repo-error.enum";
import { ReportTypeEnum } from "@/v2/module/report/type.enum";

interface IDownloadReportBody {
  businessId: string;
  reportId: string;
  reportType: ReportTypeEnum;
}

interface IDownloadReportResponse {
  file: string;
}

export class DownloadReportError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: DownloadReport");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function downloadReport(
  request: IDownloadReportBody
): Promise<IDownloadReportResponse> {
  let response: AxiosResponse;

  const mapTypes = {
    [ReportTypeEnum.CUSTOMER_DETAILS]: "customer-details",
    [ReportTypeEnum.ITEMS_SALES]: "items-sales",
    [ReportTypeEnum.LOYALTY_TRANSACTIONS]: "loyalty-transactions",
    [ReportTypeEnum.ORDER_HISTORY]: "order-history",
    [ReportTypeEnum.CUSTOMER_RATINGS]: "customer-ratings",
    [ReportTypeEnum.PAYMENT_LINKS]: "payment-links",
  };

  try {
    response = await http.get(
      `/api/v3/businesses/${request.businessId}/reports/${
        mapTypes[request.reportType]
      }/${request.reportId}/download`
    );
  } catch (err: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(err.isAxiosError);

    if (!isAxiosError) {
      throw err;
    }

    const response: IHttpErrorResponse = err;

    switch (response.status) {
      case 404:
        error.errorCode = RepoErrorReportEnum.NOT_FOUND;
        break;
      default:
        error.networkError = true;
    }

    throw new DownloadReportError(error);
  }

  return {
    file: response.data,
  };
}
